import type {
  ActionFunctionArgs,
  LinksFunction,
  LoaderFunctionArgs,
} from "@remix-run/node";

import { json, redirect } from "@remix-run/node";
import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getClientIPAddress } from "remix-utils/get-client-ip-address";
import { namedAction } from "remix-utils/named-action";
import { v4 as uuid } from "uuid";
import { z } from "zod";

import type { AlertProps } from "~/components/alert";
import { Alert } from "~/components/alert";
import { Button } from "~/components/button";
import { Field } from "~/components/field";
import { Heading, Region } from "~/components/heading";
import { GoogleIcon } from "~/components/icons";
import { returnTo } from "~/components/signup/return-to";
import { api } from "~/services/api.server";
import { auth, getAuthErrorAlertProps } from "~/services/auth.server";
import { Auth0Service } from "~/services/auth0.server";
import { returnToCookie } from "~/services/cookies.server";
import { CSRFInput, readCSRFToken, validateCSRFToken } from "~/services/csrf";
import { i18n } from "~/services/i18n.server";
import { Logger } from "~/services/logger.server";
import {
  commitSession,
  getSession,
  sessionStorage,
} from "~/services/session.server";
import { requireEnv } from "~/utils/environment";
import { getFormData } from "~/utils/get-form-data";
import { getStatus } from "~/utils/status";
import { toStatic } from "~/utils/toStatic";

export async function loader({ request }: LoaderFunctionArgs) {
  let session = await getSession(request);

  if (session.has("auth:error")) {
    let error = await getAuthErrorAlertProps(
      session.get("auth:error") as unknown,
      request,
    );
    if (error) {
      session.unset("auth:error");
      let headers = new Headers();
      headers.append("Set-Cookie", await commitSession(session));
      if (error) return json(error, { headers });
    }
  }

  let returnTo = await getReturnTo();

  // check if user is authenticated, if it is go to the returnTo
  await auth.isAuthenticated(request, { successRedirect: returnTo });

  // if it's not, save the returnTo in a cookie and return nothing
  return json(null, {
    headers: {
      "Set-Cookie": await returnToCookie.serialize(returnTo),
    },
  });

  async function getReturnTo() {
    let url = new URL(request.url);

    let cookieValue = await returnToCookie.parse(request.headers.get("Cookie"));

    let returnTo = z
      .string()
      .nullable()
      .parse(cookieValue || url.searchParams.get("returnTo"));

    if (!returnTo || !returnTo.startsWith("/") || returnTo.startsWith("//"))
      return "/dashboard";
    return returnTo;
  }
}

export async function action({ request }: ActionFunctionArgs) {
  let logger = Logger.getLogger("routes/login#action");
  let t = await i18n.getFixedT(request);

  try {
    let csrf = await readCSRFToken(request);
    if (!csrf) throw json("Invalid CSRF token.", 422);

    let formData = await getFormData(request);

    validateCSRFToken(csrf, formData);

    let auth0 = new Auth0Service(t);

    let PUBLIC_HOST = requireEnv("PUBLIC_HOST", "http://localhost:3333");
    let callbackUrl = new URL("/api/auth/callback/auth0", PUBLIC_HOST);

    let session = await getSession(request);

    return namedAction(formData, {
      async google() {
        let state = uuid();
        session.set("oauth2:state", state);
        let { url } = auth0.google(callbackUrl, state);

        return redirect(url.toString(), {
          headers: {
            "Set-Cookie": await sessionStorage.commitSession(session),
          },
        });
      },

      async default() {
        try {
          let ipAddress = getClientIPAddress(request);
          let result = await auth0.credentials(formData, ipAddress);
          if (result.status === "failure") {
            throw new Error(
              `Credentials failure: ${JSON.stringify(result.error)}`,
            );
          }

          let { token } = await api().auth.signIn(result.jwt);

          session.set(auth.sessionKey as "adminToken", token);

          let headers = new Headers({
            "Set-Cookie": await sessionStorage.commitSession(session),
          });

          return redirect(await returnTo(request, headers), { headers });
        } catch {
          return json<AlertProps>({
            type: "danger",
            title: t("Couldn't sign in") as string,
            body: t("Wrong email or password.") as string,
          });
        }
      },
    });
  } catch (exception) {
    logger.error(exception);

    return json<AlertProps>({
      type: "danger",
      title: t("There is a problem") as string,
      body: t("Couldn't log in. Please try again.") as string,
      link: {
        label: t("Contact Support") as string,
        href: "mailto:support@daffy.org",
      },
    });
  }
}

export let links: LinksFunction = () => {
  return [
    {
      rel: "preload",
      as: "image",
      href: toStatic("/auth0-login/dfw-login.webp"),
      media: "(min-width: 768px)",
    },
  ];
};

export default function Login() {
  let { t } = useTranslation();
  let alertProps = useActionData<AlertProps | undefined>() as
    | AlertProps
    | undefined;

  let loaderAlertProps = useLoaderData<AlertProps | undefined>() as
    | AlertProps
    | undefined;

  let navigation = useNavigation();
  let status = useMemo(() => {
    return getStatus(navigation.state);
  }, [navigation.state]);

  return (
    <div className="min-h-screen w-full sm:py-10 scheme-light bg-neutral-100 xl:py-24 flex sm:items-start sm:justify-center -lg:flex-col -sm:min-h-screen">
      <figure className="hidden lg:block">
        <img
          src={toStatic("/auth0-login/dfw-login.webp")}
          alt={t("Daffy - A smarter way to give")}
          className="w-full max-w-130 -xl:hidden"
        />
      </figure>

      <Region className="py-12 px-[60px] sm:rounded-xl sm:shadow max-w-130 -xl:mx-auto flex flex-col gap-6 xl:gap-8 bg-white -sm:min-h-screen">
        <header className="-xl:text-center flex flex-col">
          <Heading className="text-3xl leading-9 font-medium">
            {t("Welcome to Daffy for Work")}
          </Heading>

          <div className="mt-10">
            <Alert
              type="info"
              body={t(
                "Please use your work email to log in as an admin. Do not use your personal email.",
              )}
            />
          </div>
        </header>

        <Form method="post" className="flex -xl:flex-col gap-3">
          <CSRFInput />
          <button
            name="intent"
            value="google"
            type="submit"
            className="w-full border border-neutral-400 bg-white py-3 px-3.5 xl:py-2 xl:px-3 gap-2 flex items-center justify-center leading-none font-normal rounded-md xl:text-sm xl:leading-5 whitespace-nowrap"
          >
            <GoogleIcon />
            <span>{t("Continue with Google")}</span>
          </button>
        </Form>

        <div className="relative my-2">
          <div
            className="absolute left-0 right-0 flex items-center justify-center"
            style={{
              transform: "translateY(-50%)",
            }}
          >
            <span className="bg-white px-4 text-sm">{t("or")}</span>
          </div>
          <hr />
        </div>

        <Form method="post" className="contents">
          <CSRFInput />
          <EmailField />

          <Field>
            <Field.Label>{t("Password")}</Field.Label>
            <Field.Input
              type="password"
              name="password"
              autoComplete="current-password"
            />

            <Field.Hint>
              <Link to="/auth/reset-password" prefetch="intent">
                {t("Forgot password?")}
              </Link>
            </Field.Hint>
          </Field>

          {!!alertProps && <Alert {...alertProps} />}
          {!alertProps && !!loaderAlertProps && <Alert {...loaderAlertProps} />}

          <Button.Submit
            color="primary"
            className="rounded-full"
            status={status}
          >
            {t("Continue with Email")}
          </Button.Submit>

          <footer className="flex text-sm leading-5 gap-3 justify-center items-baseline">
            <p className="font-normal">{t("Don't have an account?")}</p>
            <Link to="/signup" className="font-medium" prefetch="intent">
              {t("Sign up")}
            </Link>
          </footer>
        </Form>
      </Region>
    </div>
  );
}

function EmailField() {
  let { t } = useTranslation();

  let [email, setEmail] = useState("");

  return (
    <div className="relative">
      <Field>
        <Field.Label>{t("Work Email address")}</Field.Label>
        <Field.Input
          type="email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(event) => {
            let email = event.currentTarget.value;
            setEmail(email);
          }}
        />
      </Field>
    </div>
  );
}
